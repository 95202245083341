// import { useMemo } from "react";
// import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";


const CTASection = () => {
  // const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY });
  // const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);


  return (
    <div className='flex items-center min-h-screen py-14'>
      <section className="grid items-center grid-cols-12 parent-size" id="contact-us">
        <aside className="h-full col-span-12 px-6 py-10 text-white bg-black sm:col-span-3 space-y-14 rounded-t-2xl sm:rounded-2xl">
          <div className="space-y-4">
            <div className="flex items-center gap-x-10">
              <h2 className="text-4xl font-medium">Let's Talk</h2>
              <i className="text-3xl bi bi-megaphone"></i>
            </div>

            <div className="space-y-6">
              <h6 className=""> <a href="mailto:info@sandlip.com" className="">info@sandlip.com</a> </h6>
              
              <div className="space-y-2">
                <h6 className=""> <a href="tel:2348023637416" className="">+234 (0) 802-363-7416</a> </h6>
                <h6 className=""> <a href="tel:2349161688902" className="">+234 (0) 916-168-8902</a> </h6>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center gap-x-10">
              <h2 className="text-4xl font-medium">Meet Us</h2>
              <i className="text-3xl bi bi-geo-alt"></i>
            </div>

            <div className="">
              <h6 className=""> Opposite Taraba State Polytechnic, Jalingo Campaus </h6>
              <h6 className=""> Nukkai </h6>
              <h6 className=""> Jalingo </h6>
              <h6 className=""> Taraba State </h6>
            </div>
          </div>
        </aside>

        <aside className="col-span-12 my-6 overflow-hidden border-black sm:col-span-9 space-y-9 rounded-b-2xl sm:rounded-bl-none sm:rounded-r-2xl sm:border-2 sm:border-l-0">
          <iframe
            className="w-full h-[30rem] sm:h-[45rem]"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d985.364743309106!2d11.328634926168645!3d8.929783403584743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNTUnNDUuOCJOIDExwrAxOSc0Mi4xIkU!5e0!3m2!1sen!2sng!4v1716815265998!5m2!1sen!2sng"
            frameBorder="0"
            title="Map view of Sandlip"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              center={center}
              zoom={10}
            >
              <Marker
                position={{ lat: 18.52043, lng: 73.856743 }}
                icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
              />
            </GoogleMap>
          )} */}
        </aside>
      </section>
    </div>
  )
}

export default CTASection