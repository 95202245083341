import { useEffect, useState } from "react";











const TestimonialSection = ({ gsap, testimonials }) => {

  const [testimonyIndex, setTestimonyIndex] = useState(0),
        [testimonyText, setTestimonyText] = useState([])





  const nextTestimony = () => {
    if (testimonyIndex < testimonials.length - 1) {
      setTestimonyIndex(testimonyIndex + 1)
      setTestimonyText(testimonials[testimonyIndex])
      return
    }

    setTestimonyIndex(0)
    setTestimonyText(testimonials[testimonyIndex])
  }

  const previousTestimony = () => {
    if (testimonyIndex > 0) {
      setTestimonyIndex(testimonyIndex - 1)
      setTestimonyText(testimonials[testimonyIndex])
    } else {
      setTestimonyIndex(testimonials.length - 1)
      setTestimonyText(testimonials[testimonyIndex])
    }
  }




  useEffect(() => {
    setTestimonyText(testimonials[0])
  }, [testimonials])
  








  return (
    <div className={`min-h-screen parent-size flex flex-col justify-center py-20 ${testimonials.length < 1 && "hidden"}`}>
      <h1 className="massive-text">Clients Share Their Delights!</h1>

      <section className="flex-1 grid grid-cols-12 py-24 text-xl">
        <aside className="col-span-12 sm:col-span-2 order-2 sm:order-1">
          <div className={`transition-all duration-500`}>
            <h6 className="">{testimonyText?.personName}</h6>
            <h6 className="text-gray-700 text-base">{testimonyText?.designation}</h6>
          </div>
        </aside>

        <aside className="col-span-12 sm:col-span-8 order-1 sm:order-2 w-full max-w-2xl mx-auto">
          <p className={`transition-all duration-500`}> {testimonyText?.testimony} </p>
        </aside>
        
        <aside className="col-span-12 sm:col-span-2 order-3 flex items-center justify-center sm:items-baseline sm:justify-start gap-x-10 text-4xl">
          <div className={`transition-all duration-700 cursor-pointer border-[3px] border-[#003380] text-[#003380] hover:bg-[#003380] hover:text-white h-14 w-14 rounded-full flex items-center justify-center outline-none`}
            onClick={previousTestimony}
          > <i className={`bi bi-arrow-left-short`}></i> </div>

          <div className={`transition-all duration-700 cursor-pointer border-[3px] border-[#003380] text-[#003380] hover:bg-[#003380] hover:text-white h-14 w-14 rounded-full flex items-center justify-center outline-none`}
            onClick={nextTestimony}
          > <i className={`bi bi-arrow-right-short`}></i> </div>
        </aside>
      </section>
    </div>
  )
}

export default TestimonialSection