import { useEffect, useRef } from "react";
import AboutUs from "./AboutUs"
import Clients from "./Clients"








const Hero = ({ gsap, gsapTimeline, listOfClients }) => {
  const imageContainerRef = useRef(null)
  const tinyTextRef = useRef(null)
  const elementRef = useRef([]);
  const digitalLettersRef = useRef([]);
  const architectLettersRef = useRef([]);
  const identityLettersRef = useRef([]);

  
  useEffect(() => {
    gsap.to(digitalLettersRef.current, { duration: 0.7, translateY: 0, ease: [0.6, 0.01, -0.05, 0.95], stagger: 0.1 });

    gsap.to(architectLettersRef.current, { duration: 0.7, translateY: 0, ease: [0.6, 0.01, -0.05, 0.95], stagger: 0.1 });

    gsap.to(identityLettersRef.current, { duration: 0.7, translateY: 0, ease: [0.6, 0.01, -0.05, 0.95], stagger: 0.1 });

    gsap.to(tinyTextRef.current, { duration: 1, translateY: 0, opacity: 1, ease: "none" });

    
    if (window.innerWidth > 768) {
      const gsapAnimation = gsapTimeline(imageContainerRef.current, 1500, true)
      .to(".heroImage", { scale: 0.9, borderRadius: '2rem' })
    
      return () => gsapAnimation.revert()
    }
  }, [gsap, gsapTimeline])
  








  return (
    <>
      <div className="h-screen sm:h-fit relative">
        <section className="parent-size grid grid-cols-12 space-y-8 massive-text pt-32 sm:pt-20">
          <aside ref={elementRef} className="col-span-12 sm:col-span-8 order-1 sm:py-4 py-2 overflow-hidden">
            {Array.from("Digital").map((letter, index) => (
              <h1 key={index} className="inline-block translate-y-[400px]" ref={(el) => (digitalLettersRef.current[index] = el)}>
                {letter}
              </h1>
            ))}
          </aside>
          
          <aside ref={tinyTextRef} className="col-span-12 sm:col-span-4 tiny-text w-full max-w-xs order-4 sm:order-2 translate-y-[100px] opacity-0">
            We build impactful, engaging, and resilient digital identities by harmonizing technology and creativity to elevate your brand in the digital landscape.
          </aside>
          
          <aside className="col-span-12 sm:text-center order-2 sm:order-3 overflow-hidden h-20 sm:h-36">
            {Array.from("Identity").map((letter, index) => (
              <h1 key={index} className="inline-block translate-y-[400px]" ref={(el) => (identityLettersRef.current[index] = el)}>
                {letter}
              </h1>
            ))}
          </aside>
          
          <aside className="col-span-12 sm:text-right sm:z-10 order-3 sm:order-4 overflow-hidden">
            {Array.from("Architects").map((letter, index) => (
              <h1 key={index} className="inline-block translate-y-[400px]" ref={(el) => (architectLettersRef.current[index] = el)}>
                {letter}
              </h1>
            ))}
          </aside>
        </section>
        
        <section ref={imageContainerRef} className="w-full h-screen sm:-translate-y-12 -z-10 sm:static absolute top-0">
          <section className="heroImage w-full h-full"
            style={{ background:"url('/img/heroImage.jpg')", backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundSize:"cover" }}
          >
            <div className="bg-white bg-opacity-70 sm:bg-opacity-20 w-full h-full"></div>
          </section>
        </section>
      </div>

      <Clients gsap={gsap} listOfClients={listOfClients} />

      <AboutUs />
    </>
  )
}

export default Hero