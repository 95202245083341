

const AboutUs = () => {



  return (
    <div id="about-us" className="min-h-screen overflow-hidden overscroll-y-none relative" style={{ background:"url('/img/team-working.jpg')", backgroundSize:"cover", backgroundPosition:"center" }}>

      <div className="py-24 text-white w-full bg-gradient-to-b from-black/80 to-black">
        <div className="parent-size space-y-16">
          <section className="w-full max-w-md flex items-start gap-x-3">
            <sup className="text-xl">01</sup>

            <div className="space-y-6">
              <h4 className="subHeading-text">Innovative</h4>
              
              <div className="text-base">
                We are a team of visionaries, constantly exploring the newest digital trends and technologies. This relentless pursuit of innovation enables us to develop fresh, unique solutions, ensuring your brand stands out in the digital landscape.
              </div>
            </div>
          </section>

          <section className="w-full max-w-md ml-auto flex items-start gap-x-3">
            <sup className="text-xl">02</sup>

            <div className="space-y-6">
              <h4 className="subHeading-text">Strategic</h4>
              
              <div className="text-base">
                We don't believe in one-size-fits-all solutions. Instead, we meticulously craft comprehensive strategies for each brand, taking into account your unique needs and goals. Our strategic approach ensures that every facet of your digital identity is intentionally designed to resonate with your target audience and to drive measurable results.
              </div>
            </div>
          </section>

          <section className="w-full max-w-md mx-auto flex items-start gap-x-3">
            <sup className="text-xl">03</sup>

            <div className="space-y-6">
              <h4 className="subHeading-text">Analytical</h4>
              
              <div className="text-base">
                We understand the power of data. By leveraging advanced analytics and insights, we make informed, data-driven decisions to optimize your brand's digital presence. This analytical approach allows us to measure success, adjust strategies as needed, and continuously drive growth.
              </div>
            </div>
          </section>

          <section className="w-full max-w-md flex items-start gap-x-3">
            <sup className="text-xl">04</sup>

            <div className="space-y-6">
              <h4 className="subHeading-text">Collaborative</h4>
              
              <div className="text-base">
                At our core, we believe in the power of collaboration. We work closely with your team, fostering a dynamic partnership that allows us to fully understand your brand's ethos and vision. This collaborative approach ensures that the digital identity we architect for you is authentic, impactful, and truly reflective of your brand.
              </div>
            </div>
          </section>
        </div>
      </div>

    </div>
  )
}

export default AboutUs