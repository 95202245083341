import { useEffect, useRef } from "react";





const teamMembers = [
  {
    firstName: "John",
    lastName: "Okeke",
    position: "Founder",
    email: "john@sandlip.com",
    image: "/img/team/jon.png"
  },
  {
    firstName: "Elizabeth",
    lastName: "Agada",
    position: "Frontend Developer",
    email: "elizabeth@sandlip.com",
    image: "/img/team/liz.png"
  },
  // {
  //   firstName: "Wisdom",
  //   lastName: "Timothy",
  //   position: "Graphic & UI/UX",
  //   email: "wisdom@sandlip.com",
  //   image: "/img/team/wiz.png"
  // },
]







const OurTeam = ({ gsap, gsapTimeline }) => {

  const pageRef = useRef(null)




  useEffect(() => {
    if (window.innerWidth > 768) {
      const teamMemberContainterArray = gsap.utils.toArray(".teamMemberContainter")
      const teamScrollAnimation = gsapTimeline(pageRef.current, 1800)


      teamScrollAnimation.to(teamMemberContainterArray[0], { x: "22rem", })
      teamScrollAnimation.to(teamMemberContainterArray[1], { x: "-22rem", }, '<')
      // teamScrollAnimation.to(teamMemberContainterArray[2], { y: "-48rem" })

      return () => teamScrollAnimation.revert() //clean up
    }
  }, [gsap, gsapTimeline])
  









  return (
    <div ref={pageRef} className="relative bg-black text-white py-12">
      
      <div className="parent-size space-y-14 min-h-screen">
        <section className="flex flex-col sm:flex-row gap-y-6">
          <h4 className="massive-text">Our team</h4>

          {/* <div className="tiny-text w-full max-w-xs sm:ml-auto">
            At Sandlip Digital Consulting, we believe that our strength lies in our team. We are a diverse group of young strategists, designers, developers, and marketers who bring a fresh perspective to the digital world. Our team members bring a wealth of experience, a commitment to innovation, and a youthful energy that drives our agency forward.
          </div> */}
        </section>
        
        {teamMembers.map((teamMember, index) => (
          <section key={index} className="w-full max-w-[18rem] mx-auto teamMemberContainter">
            <aside className="space-y-3 min-h-80 overflow-hidden bg-white relative">
              <img src={teamMember.image} alt={`${teamMember.firstName} ${teamMember.lastName}`} className="team-member-image h-96 w-auto mx-auto" />
            </aside>

            <aside className="space-y-2">
              <div className="-space-y-1">
                <h2 className="text-xl font-semibold uppercase">{teamMember.firstName} {teamMember.lastName}</h2>
                <h6 className="text-base font-medium text-gray-300">{teamMember.position}</h6>
              </div>

              <h2 className="text-sm font-medium">{teamMember.email}</h2>
            </aside>
          </section>
        ))}
      </div>

    </div>
  )
}

export default OurTeam