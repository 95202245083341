import { useEffect, useState } from "react";





const NavBar = () => {
  
  const [openMenu, setOpenMenu] = useState(false),
        [showNavBar, setShowNavBar] = useState(false)


  useEffect(() => {
    let defaultCoordinate = 0


    window.addEventListener("scroll", () => {
      const scrollHeight = window.pageXOffset || document.documentElement.scrollTop

      if (scrollHeight > defaultCoordinate) {
        setShowNavBar(true)
      } else {
        setShowNavBar(false)
      }

      defaultCoordinate =scrollHeight
    })
  }, [])



  
  
  return (
    <div className={`sticky z-20 transition-all duration-500 bg-white ${showNavBar ? "-top-40" : "top-0"}`}>
      <div className={`parent-size py-5 flex items-center justify-between gap-x-6`}>
        <a href="/" className="">
          <img src="/img/logo/logo-black.png" alt="SANDLIP" className="h-12" />
        </a>

        <section className={`fixed top-0 left-0 sm:static bg-black/90 sm:bg-transparent w-screen sm:w-fit h-screen sm:h-fit text-white sm:text-black transition-all duration-500 ${openMenu ? 'translate-y-0 translate-x-0' : '-translate-y-full -translate-x-full sm:translate-y-0 sm:translate-x-0'}`}>
          <div className="visible p-8 text-right sm:p-0 sm:invisible sm:absolute">
            <i className="text-3xl bi bi-x-lg" onClick={() => setOpenMenu(false)}></i>
          </div>

          <div className="space-y-8 text-2xl font-bold sm:space-x-6 sm:text-lg sm:font-normal">
            <a href="/#services" className="block py-4 text-center sm:inline hover:link-text sm:text-left sm:py-0" onClick={() => setOpenMenu(false)}>Services</a>
            <a href="/#about-us" className="block py-4 text-center sm:inline hover:link-text sm:text-left sm:py-0" onClick={() => setOpenMenu(false)}>About Us</a>
            {/* <a href="/#" className="block py-4 text-center sm:inline hover:link-text sm:text-left sm:py-0" onClick={() => setOpenMenu(false)}>Events</a> */}
            <a href="/#contact-us" className="block py-4 text-center sm:inline hover:link-text sm:text-left sm:py-0" onClick={() => setOpenMenu(false)}>Contact Us</a>
          </div>
        </section>

        <i className="visible text-3xl bi bi-list sm:invisible sm:absolute" onClick={() => setOpenMenu(true)}></i>
      </div>
    </div>
  )
}

export default NavBar