import { useEffect, useRef } from "react";







const services = [
  // { name: "Strategy Consulting",
  //   description: "Our team of experts will devise a custom digital plan for your brand, making sure it stands out in the online world. We aim to understand your unique needs and tailor our strategies accordingly for maximum impact.",
  //   products: [
  //     "Digital Strategy", "Content Strategy", "Research & Discovery", "Competitor Analysis"
  //   ]
  // },
  
  { name: "Brand Strategy",
    description: "We offer comprehensive product strategy services to help define your product vision, identify market opportunities, and create a roadmap for success, all grounded in deep market research and customer insights.",
    products: [
      "Brand Discovery", "Brand Identity Development", "Communication Style", "Market Segmentation", "User Testing & Research", "Customer Touchpoint Analysis"
    ]
  },

  { name: "Graphic Design",
    description: "Our team combines aesthetics and functionality, crafting engaging digital experiences that are both visually appealing and user-friendly. We ensure your digital platforms captivate your audience and are intuitive to navigate.",
    products: [
      "Art Direction", "Print/Editorial Design", "Logo Design", "Visual Content Creation", "Mobile-first Designs", "Motion Graphics & Animation", "UI Design"
    ]
  },

  // { name: "Product Management",
  //   description: "We handle all stages of product management, overseeing strategy, development, launch, and ongoing improvement. Our product managers coordinate resources and ensure that product goals are achieved efficiently.",
  //   products: [
  //     "Market & Customer Research", "Product Strategy & Planning", "User Experience (UX) Design", "Product Training & Documentation", "Product Lifecycle Management", "Product Performance Analysis"
  //   ]
  // },

  { name: "Web Development",
    description: "Our experienced designers and developers work together to create visually appealing, user-friendly websites that embody your brand's ethos and communicate its values effectively.",
    products: [
      "Responsive Web Design", "Front-end Development", "Back-end Development", "E-commerce Development", "Search Engine Optimization (SEO)", "Web Analytics and Tracking", "Website Optimization and Performance", "Website Maintenance and Support"
    ]
  },

  { name: "Blockchain Services",
    description: "Our skilled team designs and develops secure blockchain solutions. From decentralized applications to smart contracts, we ensure your transactions are transparent, secure, and efficient, enhancing your digital operations.",
    products: [
      "Blockchain Consulting", "Smart Contract Development", "DApp Development", "Cryptocurrency Development", "Blockchain Integration", "Security Audits & Testing"
    ]
  },

  { name: "Mobile Development",
    description: "We create engaging, user-friendly mobile apps, managing the process from concept and design to development and launch. Our aim is to provide seamless experiences for users across all platforms.",
    products: [
      "App Development", "Quality Assurance & Testing", "App Store Optimization", "App Maintenance & Support", "Concept Ideation", "User Experience Design"
    ]
  }
]






const randomShapes = [
  { name: "lightbulb",
    additionalClass: "sm:bottom-12 bottom-44 left-32 opacity-50 sm:opacity-80",
    color: ""
  },
  { name: "gear",
    additionalClass: "bottom-1/2 sm:right-32 right-0 opacity-50 sm:opacity-80",
    color: ""
  },
  { name: "layout-wtf",
    additionalClass: "sm:top-10 top-40 left-1/3 opacity-50 sm:opacity-80",
    color: ""
  },
]







const OurServices = ({ gsap, gsapTimeline }) => {
  const containerRef = useRef(),
        servicesContainerRef = useRef()


  useEffect(() => {
    if (window.innerWidth > 768) {
      const shapesArray = gsap.utils.toArray(".shapeContainer"),
            scrollAnimation = gsapTimeline(containerRef.current, 4000, true)


      scrollAnimation.to(servicesContainerRef.current, { x: "-100%" })
      scrollAnimation.to(shapesArray, { scale: 1 }, 0)
    
      return () => scrollAnimation.revert() //clean up
    }
  }, [gsap, gsapTimeline])
  





  return (
    <div id="services" className="py-10 text-white bg-black sm:py-0">
      <div className="relative sm:h-screen" ref={containerRef}>
        {randomShapes.map((randomShape, index) => (
          <div key={index} className={`shapeContainer absolute sm:scale-[0.2] ${randomShape.additionalClass}`}>
            <i className={`bi bi-${randomShape.name} text-9xl gradient-text`}></i>
          </div>
        ))}




        <div className="relative min-h-full overflow-hidden parent-size overscroll-y-auto">
          <div ref={servicesContainerRef} className="flex flex-col space-y-12 sm:flex-row sm:gap-x-16 sm:absolute sm:top-0 sm:left-32">
            <section className="flex items-center">
              <h3 className="massive-text sm:text-[45vw]"> Services </h3>
            </section>

            <section className="items-center space-y-16 gap-x-20 sm:flex sm:px-40 sm:space-y-0">
              {services.map((services, index) => (
                <div key={index} className={`w-full max-w-md flex flex-col flex-shrink-0 z-10 space-y-6`}>
                  <h4 className="subHeading-text"> {services.name} </h4>

                  <div className={`flex-1 space-y-1`}>
                    {services.products.map((product, productIndex) => (
                      <h4 key={productIndex} className="flex items-end gap-x-2">
                        <div className="text-[#3fff00] text-sm"> 0{productIndex + 1} </div>
                        <div className=""> {product} </div>
                      </h4>
                    ))
                    }
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>

      </div>
    </div>
  )
}

export default OurServices