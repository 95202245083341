import { Route, Routes } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Footer from "./components/Footer";
import CTASection from "./components/CTASection";
import NavBar from "./components/NavBar";
import HomePage from "./pages/HomePage";


gsap.registerPlugin(ScrollTrigger)




function App() {



  const gsapTimelineAnimation = (elementContainer, timeToFinish, pinElement = false) => {
    const scrollAnimation = gsap.timeline({
      scrollTrigger: {
        trigger: elementContainer,
        pin: pinElement,
        end: `+=${timeToFinish}`,
        scrub: 1
      }
    })

    return scrollAnimation
  }





  return (
    <div className="relative text-black text-lg">
      <NavBar />

      <div className="space-y-16 sm:space-y-32">
        <Routes>
          <Route path="/" element={ <HomePage
            gsap={gsap}
            gsapTimeline={gsapTimelineAnimation}
          /> } />
        </Routes>

        <CTASection />
      </div>

      <Footer />
    </div>
  );
}

export default App;
