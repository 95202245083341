import { useEffect, useState } from "react";
import axios from "axios";
import Hero from '../components/Hero'
import OurServices from '../components/OurServices'
import OurTeam from '../components/OurTeam'
// import CollaborationSection from '../components/CollaborationSection'
import TestimonialSection from '../components/TestimonialSection'




const HomePage = ({ gsap, gsapTimeline }) => {

  const [listOfClients, setListOfClients] = useState([]),
        [testimonials, setTestimonials] = useState([])







  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_API, { headers: {'Content-Type': 'application/json'} })
    .then(results => {
      // console.log(results.data);
      setListOfClients(results.data.clients)
      setTestimonials(results.data.testimonials)
    })
    .catch(console.error)
  }, [])









  return (
    <div>
      <Hero
        gsap={gsap}
        gsapTimeline={gsapTimeline}
        listOfClients={listOfClients}
      />

      <div className="">
        <OurServices
          gsap={gsap}
          gsapTimeline={gsapTimeline}
        />

        <TestimonialSection
          gsap={gsap}
          testimonials={testimonials}
        />

        <OurTeam
          gsap={gsap}
          gsapTimeline={gsapTimeline}
        />

        {/* <CollaborationSection /> */}
      </div>
    </div>
  )
}

export default HomePage
