import { useLayoutEffect } from "react";






const Clients = ({ gsap, listOfClients }) => {


  useLayoutEffect(() => {
    setTimeout(() => {
      var totalWidth = 0;
      var clientLogos = gsap.utils.toArray(".clientLogo");


      clientLogos.forEach((val, key, arr) => {
        if (key === 0) {
          totalWidth = clientLogos[arr.length - 1].clientWidth;
        } else if (val.previousElementSibling) {
          let width = val.previousElementSibling.clientWidth;
          totalWidth = totalWidth + width;
        }

        gsap.set(val, { x: totalWidth });
      });

      var tl = gsap.timeline();
      tl.to(".clientLogo", {
        duration: 70,
        ease: "none",
        x: `+=${totalWidth}`,
        modifiers: {
          x: gsap.utils.unitize(x => parseFloat(x) % totalWidth)
        },
        repeat: -1
      });
  
      return () => tl.revert()
    }, 500);
  }, [gsap, listOfClients])



  return (
    <div className={`parent-size overflow-hidden h-32 ${listOfClients.length < 1 && "hidden"}`}>
      <section className="relative w-full -left-40 top-6">
        {listOfClients.map((logo, index) => (
          <p className="clientLogo absolute px-10">
            <img src={logo} alt={`Client ${index + 1}`} className="h-20" />
          </p>
        ))}
      </section>
    </div>
  );
};

export default Clients;
